.player {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  background-color: black;
  width: auto;
  height: auto;

  justify-content: center;
}

.reactPlayer {
  max-width: 800px;
  position: absolute;
  top: 0;
  margin-top: 0px;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 900px) {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .reactPlayer {
    max-width: 100%;
  }
}
