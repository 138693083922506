:root {
  /* Color */
  --white-color: #fafafa;
  --black-color: #212121;
  --blue-color: #2962ff;
  --red-color: #d50000;
  --grey-dark-color: #5b5959;
  --grey-light-color: #e0e0e0;
  /* Size */
  --padding: 12px;
  --avata-size: 36px;

  /* Font Size */
  --font-large: 4rem;
  --font-medium: 1rem;
  --font-small: 0.7rem;
  --font-mico: 0.5rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto;
}

ul {
  list-style: none;
}

button,
button:focus {
  border: none;
  cursor: pointer;
  outline: none;
}

body > .info {
  padding: var(--padding);
}

.info {
  padding: 0 var(--padding);
  border-top: 10px solid var(--grey-light-color);
  border-bottom: 10px solid var(--grey-light-color);
}

.info > .title {
  font-size: var(--font-small);
  color: var(--grey-dark-color);
  margin-bottom: calc(var (--padding) / 2);
}

.info .item {
  display: flex;
  margin-top: var(--padding);
}

.info .item .img {
  flex: 1 1 50%;
  margin-right: var(--padding);
}

.info .item .img img {
  width: 100%;
  border-radius: 5%;
}

.info .item .img .tt {
  text-align: center;
}

.info .item .info {
  flex: 1 1 50%;
}

.info .item .img {
  font-size: var(--font-medium);
  color: var(--grey-dark-color);
}

.info .item .info {
  display: flex;
  flex-direction: column;
}

.info .item .info .title {
  font-size: var(--font-medium);
}

.info .text {
  font-size: var(--font-medium);
  color: var(--grey-dark-color);
}

.info .item .info .name,
.info .item .info .views {
  font-size: var(--font-micro);
  color: var(--grey-dark-color);
}

/* Up Next */
.upNext {
  padding: 0 var(--padding);

  border-top: 10px solid var(--grey-light-color);
  border-bottom: 10px solid var(--grey-light-color);
}

.upNext > .title {
  font-size: var(--font-medium);
  color: var(--grey-dark-color);
  margin-bottom: calc(var (--padding) / 2);
}

.upNext .item {
  display: flex;
  margin-top: var(--padding);
}

.upNext .item .img {
  font-size: var(--font-medium);
  color: var(--grey-dark-color);
}

.upNext .item .img {
  flex: 1 1 50%;
  margin-right: var(--padding);
}

.upNext .item .img img {
  width: 100%;
  border-radius: 5%;
}

.upNext .item .img .tt {
  text-align: center;
}

.upNext .item .info {
  flex: 1 1 50%;
}

.upNext .item .info {
  display: flex;
  flex-direction: column;
}

.upNext .item .info .title {
  font-size: var(--font-medium);
}

.upNext .item .info .name,
.upNext .item .info .views {
  font-size: var(--font-medium);
  color: var(--grey-dark-color);
}

.infoAndupNext {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .infoAndupNext {
    flex-direction: row;
    margin: var(--padding) 0;
  }
}

@media screen and (max-width: 768px) {
  .infoAndupNext {
    flex-direction: column;
    margin: var(--padding) 0;
  }

  .info {
    padding: 0 var(--padding);
    border-top: 10px solid var(--grey-light-color);
    border-bottom: none;
  }
  .upNext {
    padding: 0 var(--padding);
    border-top: 1px solid var(--grey-light-color);
    border-bottom: 10px solid var(--grey-light-color);
  }
}
