* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto;
}

.Container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.Container hr {
  position: absolute;
  left: 0;
  width: 25px;
  border: 1px solid #ffffff;
}

.Container h3 {
  color: rgb(110, 108, 108);
}
