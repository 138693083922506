* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: white;
  font-family: Roboto;
}

.MostList {
  background-color: #141313;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0.7rem;
}
.MostList h2 {
  font-weight: 900;
  font-size: 1.2rem;
  color: aliceblue;
  text-align: center;
}

.MostList .List ul {
  color: white;
  list-style-type: none;
  padding: 0rem;
  margin: 0.3rem;
}

.MostList .Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2% 1%;
  /*
  margin-left: 2%;
  margin-right: 2%; */
}

.MostList .Container .List {
  flex-basis: 33.33%;
  background-color: black;
  box-shadow: 0 0 12px 4px white;
  border-radius: 5px;
  margin-bottom: 2px;
}

.MostList .List ul li {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
}

.MostList .List ul a {
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
}
